@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&family=Dancing+Script:wght@500&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 10px;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: #fff;
  color: #333;
  font-size: 2.1rem;
  line-height: 1.4rem;
}

a {
  text-decoration: none;
  color: #2196f3;
}

ul {
  list-style-type: none;
}

p {
  font-size: 2.1rem;
  line-height: 2.5rem;
}

/* COLORS */

.green {
  background-color: #4caf50;
}

.blue {
  background-color: #2196f3;
}

.red {
  background-color: #f44336;
}

.purple {
  background-color: #9c27b0;
}

.orange {
  background-color: #ff9800;
}

.correct {
  color: #4caf50;
}

.wrong {
  color: #f44336;
}

/* NAVBAR */

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ececec;
  color: #2196f3;
  opacity: 0.8;
  width: 100%;
  height: 70px;
  position: fixed;
  font-weight: 600;
  top: 0px;
  padding: 0 4rem;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: 'Indie Flower', cursive;
  font-size: 1.6rem;
}

.nav-links li {
  margin-left: 2rem;
}

.nav-home {
  display: flex;
  align-items: center;
}

.nav-home span {
  /* font-family: 'Indie Flower', cursive; */
  /* font-family: 'Annie Use Your Telescope', cursive; */
  /* font-family: 'Dancing Script', cursive; */
  font-family: 'Indie Flower', cursive;
  font-size: 3rem;
  margin-left: 9rem;
}

.nav-home img {
  height: 70px;
  position: fixed;
  top: 0px;
}

/* CONTAINERS */
.wrapper {
  display: flex;
  justify-content: center;
}

.container {
  margin: 8rem 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1rem;
}

.map-cont,
.buttons-cont,
.corr-btn-cont,
.next-btn-cont {
  /* border: 1px #ccc solid; */
  padding: 0.5rem;
}

.map-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons-cont {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.corr-btn-cont {
}

.next-btn-cont {
}

.pilt {
  object-fit: scale-down;
  min-width: 500px;
  min-height: 500px;
  max-width: 500px;
  max-height: 500px;
}

.icon {
  font-size: 12rem;
  margin-bottom: 3rem;
}

.logo {
  margin-top: 4.5px;
  max-height: 60px;
}

/* BUTTONS */
.btn {
  cursor: pointer;
  margin: 1rem;
  padding: 1rem;
  font-size: 1.8rem;
  background-color: #f3d036;
  color: #333;
  border: none;
  border-radius: 3px;
  outline: none;
  text-align: center;
}

.btn:hover {
  background-color: #b69c29;
}

.btn-correct {
  cursor: auto;
  background-color: #4caf50;
  color: #fff;
}

.btn-wrong {
  cursor: auto;
  background-color: #f44336;
}

.btn-new-set {
  background-color: #2196f3;
  color: #fff;
}

.btn-new-set:hover {
  background-color: #186aad;
}

.btn-new-set-disabled {
  margin: 1rem;
  padding: 1rem;
  font-size: 1.8rem;
  background-color: #eee;
  color: #999;
  border: none;
  border-radius: 3px;
  outline: none;
  text-align: center;
}

/* COLLECTIONS */

.collection {
  border: 1px solid #e0e0e0;
  margin: 1.6rem 0;
  border-radius: 3px;
}

.collection-item {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  line-height: 1.5rem;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.collection-item:last-child {
  border: none;
}

/* FORM */

form {
  margin: 1.6rem;
}

form input[type='text'] {
  width: 100%;
  margin-bottom: 2rem;
  border: solid 1px #ccc;
  padding: 1rem;
  font-size: 1.8rem;
  border-radius: 3px;
  outline: none;
}

form input:focus {
  border: solid 2px #487ef1;
}

/* LAYOUT ELEMENTS */

.about {
  font-size: 1.7rem;
  line-height: 2rem;
}

.alert {
  margin: 1.5rem;
  padding: 1.2rem;
  font-size: 1.8rem;
  background-color: #f4f4f4;
  color: #333;
  border: none;
  border-radius: 3px;
  outline: none;
  text-align: center;
}

.status {
  color: red;
}

/* MEDIA QUERIES */

@media (min-width: 1200px) {
  .container {
    width: 60%;
  }

  .map-cont {
    width: 700px;
  }
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .map-cont {
    width: 80vw;
  }

  .nav-home span {
    display: none;
  }
}

/* @media (max-height: 500px) {
  .container {
    margin: 0;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .container {
    width: 80%;
  }
} */
